<template>
  <DashboardTemplate>
    <el-card class="box-card" v-loading.lock="loading">
      <h2>Waiting For All Team’s Sale Order</h2>
      <div class="text-right">
        <p><span class="font-weight-bold">Team submit:</span> <span :class="groupSend.length>=tableData.length ? 'color-success': ''">{{groupSend.length}}/{{tableData.length}}</span></p>
      </div>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column
          type="index"
          width="70"
          align="center"
          label="ลำดับ">
        </el-table-column>
        <el-table-column prop="name" header-align="center" label="Team" width="400"></el-table-column>
        <el-table-column align="center" header-align="center" prop="status" label="Status">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1" class="color-success">Submit</div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="time" label="Time">
          <template slot-scope="scope">
            {{scope.row.time | timeTh}}
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="mg-t-3">
        <el-col :span="6">
          <button class="mg-y-5 w-100 btn-primary" type="submit" @click="confirmSend()" round>Announce Sold Q</button>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog :visible.sync="confirmModal" width="30%">
      <div v-if="groupModal.length > 0">
        <h3 class="text-center">ยืนยันการประกาศผล Sold Quantity</h3>
        <h3 class="text-center">รายชื่อทีมที่ยังไม่ได้ส่งรายการขาย</h3>
        <p v-for="(data,index) in groupModal">{{index+1}}. {{data.name}}</p>
      </div>
      <div v-else>
        <h3 class="text-center">ยืนยันการประกาศผล Sold Quantity</h3>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmModal = false">Cancel</el-button>
        <el-button type="primary" @click="submit()" :loading="loadingConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </DashboardTemplate>
</template>
<script>
import { updateCurrent } from "@/service/updateCurrent";
import { HTTP } from "@/service/axios";
import { alertFailError } from "@/service/alertMsg";
import DashboardTemplate from "@/template/DashboardTemplate";
export default {
  components: {
    DashboardTemplate
  },
  data() {
    return {
      tableData: [],
      confirmModal: false,
      loading:false,
      groupSend: [],
      loadingConfirm:false,
      groupModal:[]
    };
  },
  mounted() {
    this.loading = true
    this.fetchData();
    this.onTeamSubmit()
  },
  computed: {
    room() {
      return this.$store.state.room;
    }
  },
  methods: {
    onTeamSubmit(){
      this.$socket.client.on('resCheckPointSoldQ',(res)=>{
  
        if(res.success){
          this.groupSend = []
          if(this.room._id == res.roomId){
            this.tableData = res.obj;
            res.obj.map(item => {
              if (item.status == 1) {
                this.groupSend.push(item);
              }
            })
          } 
        }
      });
    },
    fetchData() {
      this.groupSend = []
      HTTP.get(
        "/status/order/" + this.room._id + "/" + this.room.currentRound
      ).then(res => {
 
        this.tableData = res.data.obj;
        res.data.obj.map(item => {
            if (item.status == 1) {
              this.groupSend.push(item);
            }
          })
      }).finally(() =>{
        setTimeout(()=>{
          this.loading = false
        },500)
      });
    },
    async confirmSend() {
      this.groupModal = [];
      await this.tableData.map(item => {
        if (item.status != 1) {
          this.groupModal.push(item);
        }
      });
      this.confirmModal = true;
    },
    async submit() {
      this.loadingConfirm = true
      let data = {
        round: this.room.currentRound,
        roomId: this.room._id
      };
      HTTP.post("/comrate", data).then(async (res) => {
  
        if (res.data.success || res.data.data == 'announced soldQ this round') {
          this.room.current = 'Sold Q Announcement'
          await this.$store.dispatch('updateCurrent',this.room);
          await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
          this.$router.push("/sold-quantity").catch(err => {})
        }
      }).finally(()=>{
        setTimeout(()=>{
          this.loadingConfirm = false
        },500)
      })
    }
  }
};
</script>